import React from 'react';
import { Controller } from 'react-hook-form';

function InputField(props) {
  const { form, name, placeHolder } = props;
  const { control } = form;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { name, value, onBlur, onChange }, fieldState: { error } }) => (
        <div className="input-container">
          <input
            placeholder={placeHolder}
            onChange={onChange}
            onBlur={onBlur}
            name={name}
            value={value}
            style={{ height: '32px', border: '1px solid rgb(108, 173, 238)', borderRadius: '4px' }}
          />
          {error?.message && <span className="helper-text">{error?.message}</span>}
        </div>
      )}
    />
  );
}

export default InputField;
