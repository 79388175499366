import React, { useState } from 'react';
import { Controller } from 'react-hook-form';

function PasswordField(props) {
  const { form, name, placeHolder } = props;
  const { control } = form;
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { name, value, onBlur, onChange }, fieldState: { error } }) => (
        <div className="input-container password-container">
          <input
            id="password"
            type={showPassword ? 'text' : 'password'}
            placeholder={placeHolder}
            onChange={onChange}
            onBlur={onBlur}
            name={name}
            value={value}
            style={{ height: '32px', border: '1px solid rgb(108, 173, 238)', borderRadius: '4px' }}
          />
          <span className="toggle-password" onClick={toggleShowPassword}></span>
          {error?.message && <span className="helper-text">{error?.message}</span>}
        </div>
      )}
    />
  );
}

export default PasswordField;
