import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import './App.css';
import InputField from './components/InputField';
import * as yup from 'yup';
import PasswordField from './components/PasswordField';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

function App() {
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const schema = yup.object().shape({
    phone: yup.string().required('Vui lòng nhập số điện thoại'),
    password: yup.string().required('Vui lòng nhập mật khẩu').min(6, 'Mật khẩu ít nhất 6 ký tự'),
    retypePassword: yup
      .string()
      .required('Vui lòng nhập lại')
      .oneOf([yup.ref('password')], 'Nhập lại không khớp'),
  });
  const form = useForm({
    defaultValues: {
      phone: '',
      email: '',
      password: '',
      retypePassword: '',
    },

    resolver: yupResolver(schema),
  });

  const handleSubmit = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    setTimeout(() => {
      setIsSubmitting(false);
      enqueueSnackbar(
        'Cảm ơn bạn đã đăng ký thông tin chúng tôi sẽ liên hệ trong vòng 24h làm việc để xác nhận hoàn tất đăng ký tài khoản',
        { variant: 'success' }
      );
    }, 2000);
  };

  return (
    <div className="App">
      <div className="container">
        <h3 className="title">Đăng ký sử dụng ZPOS</h3>
        <p className="title-descriptions">
          Số điện thoại và mật khẩu là tài khoản để đăng nhập vào hệ thống
        </p>
        <form onSubmit={form.handleSubmit(handleSubmit)} autoComplete="off">
          <div className="form-controls">
            <div className="control">
              <InputField name="phone" placeHolder="Số điện thoại *" form={form} />
            </div>
            <div className="control">
              <PasswordField name="password" placeHolder="Mật khẩu *" form={form} />
            </div>
            <div className="control">
              <PasswordField name="retypePassword" placeHolder="Nhập lại mật khẩu *" form={form} />
            </div>

            <button
              className={`button ${isSubmitting ? 'button-disabled' : ''}`}
              disabled={isSubmitting}
              type="submit"
            >
              {isSubmitting ? 'Đang kiểm tra' : 'Đăng ký'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default App;
